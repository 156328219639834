import React from 'react'

import "./Impressum.css"

function Impressum() {
  return (
    <div className="impressum-container">
      <h1>Impressum</h1>

<p>Alexander Kappes<br />
Angerlohstr. 55<br />
80997 M&uuml;nchen</p>

<h2>Kontakt</h2>
<p>Telefon: 01629334162<br />
E-Mail: ikb.kappes@gmail.com</p>

<h2>Umsatzsteuer-ID</h2>
<p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
DE258813010</p>

<h2>Berufsbezeichnung und berufsrechtliche Regelungen</h2>
<p>Berufsbezeichnung:<br />
Diplom Ingenieur FH</p>
<p>Verliehen in:<br />
Deutschland</p>
<h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
<p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
    </div>
  )
}

export default Impressum